import React, {useImperativeHandle, useRef} from 'react'
import {useTranslation} from '@lib/intl/useTranslations'
import WizButton from '@component/buttons/WizButton'
import Text from '@component/Text/Text'
import WizContentModal from '@component/modal/WizContentModal'
import {useAlertModalStore} from '@store/AlertModalStore'

export interface IAlertModal {
    title?: string
    desc?: string
    confirmText?: string
    cancelText?: string
    isShowConfirm?: boolean
    isShowCancel?: boolean
    onClickConfirm?: () => void
}

interface Props {}

const AlertModal = (props: Props, ref: any) => {
    const t = useTranslation()
    const modalRef = useRef<any>()

    const alertModal = useAlertModalStore(state => state.alertModal)

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        },
    }))

    return (
        <WizContentModal size={'400'} title={alertModal?.title} ref={modalRef}>
            <Text className={'text-body1 text-gray01 dark:text-dark_gray01'}>{alertModal?.desc}</Text>
            <div className={'flex gap-x-[10px] mt-[20px]'}>
                {alertModal?.isShowCancel && (
                    <WizButton
                        className={'flex-1'}
                        text={alertModal?.cancelText ?? t('modal.commonModal.cancel.default')}
                        buttonType={'border'}
                        onClick={() => modalRef.current.close()}
                    />
                )}
                {alertModal?.isShowConfirm && (
                    <WizButton
                        className={'flex-1'}
                        text={alertModal?.confirmText ?? t('modal.commonModal.confirm.default')}
                        buttonType={'primary'}
                        onClick={() => {
                            alertModal?.onClickConfirm && alertModal?.onClickConfirm()
                            modalRef.current.close()
                        }}
                    />
                )}
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(AlertModal)
