import React, {useContext, useState} from 'react'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import Text from '@component/Text/Text'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useTranslation} from '@lib/intl/useTranslations'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import WizButton from '@component/buttons/WizButton'
import {useNavigate} from 'react-router-dom'
import {FindPasswordContext} from '@feature/common/components/LoginModal/routes/FindPasswordRoutes/FindPasswordRoutes'
import {isEmpty} from '@util/strings'
import {apiSendPasswordResetCode} from '@api/auths/findPassword/SendPasswordResetCode'
import useSnackbar from '@hook/useSnackbar'
import {emailValidation} from '@util/validation'
import {createLog} from '@util/logs'

interface IProps extends ILoginModalProps {}

const ScreenForgotPassword: React.FC<IProps> = ({closeModal}) => {
    const t = useTranslation()
    const navigate = useNavigate()

    const {email, setEmail} = useContext(FindPasswordContext)
    const [isLoading, setIsLoading] = useState(false)

    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT)
    const [inputErrorMessage, setInputErrorMessage] = useState('')

    const {showErrorSnackbar} = useSnackbar()

    const onClickSubmit = async () => {
        if (!emailValidation(email)) {
            createLog('error', 'login_forgot_password_submit_email_failed_wrong_email_type')
            setInputState(InputState.ERROR)
            setInputErrorMessage(t('loginModal.forgotPassword.submit.res.fail.wrongEmailType'))
            return
        }

        createLog('event', 'login_forgot_password_submit_email')

        setIsLoading(true)
        const {data, error} = await apiSendPasswordResetCode(email)
        if (data?.success === true) {
            navigate('/find_password_code')
        } else {
            createLog('error', 'login_forgot_password_submit_email_failed')
            showErrorSnackbar(error)
        }
        setIsLoading(false)
    }

    return (
        <LoginModalLayout
            closeModal={closeModal}
            title={t('loginModal.forgotPassword.page.title')}
            sideImageType={'safety_first'}>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                {t('loginModal.forgotPassword.desc')}
            </Text>
            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                {t('loginModal.forgotPassword.password.label')}
            </Text>
            <WizInputText
                text={email}
                state={inputState}
                autoComplete={'username'}
                errorDescription={inputErrorMessage}
                autoFocus={true}
                className={'mt-[5px]'}
                onChanged={text => {
                    setEmail(text)
                    setInputState(InputState.DEFAULT)
                }}
                placeholder={t('loginModal.forgotPassword.password.input.placeholder')}
                onPressEnter={onClickSubmit}
            />
            <WizButton
                buttonType={'primary'}
                text={t('loginModal.forgotPassword.submit.continue.text')}
                className={'mt-[10px] w-full'}
                onClick={onClickSubmit}
                disabled={isEmpty(email)}
                isLoading={isLoading}
            />
        </LoginModalLayout>
    )
}

export default ScreenForgotPassword
