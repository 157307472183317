import React, {useContext, useEffect, useMemo, useState} from 'react'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import Text from '@component/Text/Text'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useTranslation} from '@lib/intl/useTranslations'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import WizButton from '@component/buttons/WizButton'
import {FindPasswordContext} from '@feature/common/components/LoginModal/routes/FindPasswordRoutes/FindPasswordRoutes'
import {equalString, isEmpty, isNotEmpty} from '@util/strings'
import useSnackbar from '@hook/useSnackbar'
import {apiConfirmPasswordResetCode} from '@api/auths/findPassword/ConfirmPasswordResetCode'
import {useLoginStore} from '@store/LoginStore'
import useLoginModal from '@feature/common/components/LoginModal/hooks/useLoginModal'
import {
    PasswordSecureType,
    PasswordSecureTypes,
    usePasswordSecure,
} from '@feature/common/components/LoginModal/screen/hooks/usePasswordSecure'
import {
    PASSWORD_CHAR_EXP,
    PASSWORD_CHECK_MEDIUM_EXP,
    PASSWORD_CHECK_STRONG_EXP,
    PASSWORD_CHECK_WEEK_EXP,
} from '@util/validation'
import WizProgressBar from '@component/progress/WizProgressBar'
import TagText from '@component/Text/TagText'
import {createLog} from '@util/logs'
import {apiFetchMe} from '@api/user/account/me'
import {useRouter} from 'next/router'

interface IProps extends ILoginModalProps {}

const ScreenForgotPasswordSetPassword: React.FC<IProps> = ({closeModal}) => {
    const t = useTranslation()

    const {email, code, password, setPassword, confirmPassword, setConfirmPassword} = useContext(FindPasswordContext)
    const [passwordSecure, setPasswordSecure] = useState<PasswordSecureType>(PasswordSecureTypes.NONE)
    const [isWrongSecure, setIsWrongSecure] = useState(false)
    const {progress, progressStyle, secureText, secureTextStyle} = usePasswordSecure(passwordSecure)

    const {showErrorSnackbar} = useSnackbar()

    const [isLoading, setIsLoading] = useState(false)
    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT)
    const [inputErrorMessage, setInputErrorMessage] = useState('')
    const login = useLoginStore(state => state.login)
    const {redirectAfterLogin} = useLoginModal()
    const {showSnackbar} = useSnackbar()
    const router = useRouter()

    const onClickSubmit = async () => {
        if (isDisableSubmitButton) return

        createLog('event', 'login_forgot_password_submit_password')
        setIsLoading(true)
        const {data, error} = await apiConfirmPasswordResetCode(email, code, password)
        if (data) {
            login(data?.user_id, data?.access_token)
            const {data: me, error} = await apiFetchMe()
            const userName =
                router?.locale === 'ko' && me?.nicknames?.at(0)?.nickname ? me?.nicknames?.at(0)?.nickname : me?.name
            closeModal()
            showSnackbar(t('globalNavigation.multiLogin.changeAccount.text', {username: userName}))
            redirectAfterLogin()
        } else {
            createLog('error', 'login_forgot_password_submit_password_failed')
            showErrorSnackbar(error)
        }
        setIsLoading(false)
    }

    const isDisableSubmitButton = useMemo(
        () =>
            isEmpty(password) ||
            isEmpty(confirmPassword) ||
            !equalString(password, confirmPassword) ||
            passwordSecure === PasswordSecureTypes.NONE,
        [password, confirmPassword, passwordSecure],
    )

    useEffect(() => {
        if (isNotEmpty(password) && isNotEmpty(confirmPassword) && !equalString(password, confirmPassword)) {
            setInputState(InputState.ERROR)
            setInputErrorMessage(t('loginModal.forgotPasswordSetPassword.submit.res.fail.incorrectCode'))
        } else {
            setInputState(InputState.DEFAULT)
        }
    }, [password, confirmPassword, t])

    const checkPasswordSecure = (password: string) => {
        let newSecure: PasswordSecureType = PasswordSecureTypes.NONE
        const isWrongSecurePass = password.length > 0 && !password.match(PASSWORD_CHAR_EXP)
        if (password.match(PASSWORD_CHECK_WEEK_EXP)) {
            newSecure = PasswordSecureTypes.WEAK
        }
        if (password.match(PASSWORD_CHECK_MEDIUM_EXP)) {
            newSecure = PasswordSecureTypes.MEDIUM
        }
        if (password.match(PASSWORD_CHECK_STRONG_EXP)) {
            newSecure = PasswordSecureTypes.STRONG
        }

        setIsWrongSecure(isWrongSecurePass)
        setPasswordSecure(newSecure)
        setPassword(password)
    }

    return (
        <LoginModalLayout
            closeModal={closeModal}
            title={t('loginModal.forgotPasswordSetPassword.page.title')}
            sideImageType={'safety_first'}
            hideBackButton={true}>
            <div className={'space-y-[10px]'}>
                <Text className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                    {t('loginModal.forgotPasswordSetPassword.desc')}
                </Text>
                <div>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                        {t('loginModal.forgotPasswordSetPassword.password.label')}
                    </Text>
                    <WizInputText
                        text={password}
                        onChanged={checkPasswordSecure}
                        type={'password'}
                        className={'mt-[5px]'}
                        autoFocus={true}
                        placeholder={t('loginModal.forgotPasswordSetPassword.password.input.placeholder')}
                    />
                </div>
                <div className={'space-y-[3px]'}>
                    <WizProgressBar
                        width={progress}
                        className={'bg-gray06 dark:bg-dark_gray06'}
                        barClassName={`h-[6px] ${progressStyle}`}
                    />
                    <div>
                        <TagText className={`text-body3 ${secureTextStyle}`}>{secureText}</TagText>
                        {isWrongSecure && (
                            <Text className={`text-body3 text-red_shade dark:text-dark_red_shade`}>
                                {t('loginModal.password.secure.wrong.text')}
                            </Text>
                        )}
                    </div>
                </div>
                <div>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[20px]'}>
                        {t('loginModal.forgotPasswordSetPassword.confirmPassword.label')}
                    </Text>
                    <WizInputText
                        text={confirmPassword}
                        onChanged={text => setConfirmPassword(text)}
                        state={inputState}
                        errorDescription={inputErrorMessage}
                        type={'password'}
                        className={'mt-[5px]'}
                        placeholder={t('loginModal.forgotPasswordSetPassword.confirmPassword.input.placeholder')}
                    />
                </div>
                <WizButton
                    buttonType={'primary'}
                    text={t('loginModal.forgotPasswordSetPassword.submit.confirmPassword.text')}
                    className={'w-full'}
                    onClick={onClickSubmit}
                    isLoading={isLoading}
                    disabled={isDisableSubmitButton}
                />
            </div>
        </LoginModalLayout>
    )
}

export default ScreenForgotPasswordSetPassword
