import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from '@lib/intl/useTranslations'
import Span from '@component/Text/Span'

interface IProps {
    className?: string
    size?: 'normal' | 'small'
    type?: 'normal' | 'text'
}

const ProTag: React.FC<IProps> = ({className, size = 'normal', type = 'normal'}) => {
    const t = useTranslation()

    return (
        <>
            {type === 'normal' ? (
                <div className={`${className} flex items-center justify-center`}>
                    {size === 'normal' && (
                        <div className={'rounded-[5px] bg-gradient_pro px-[10px] py-[3px]'}>
                            <Text
                                className={'text-body3 font-bold text-white whitespace-nowrap'}
                                enablePreWhiteSpace={false}>
                                {t('accountPro.currentState.proBadge')}
                            </Text>
                        </div>
                    )}

                    {size === 'small' && (
                        <div className={'rounded-[2px] bg-gradient_pro px-[5px]'}>
                            <Text
                                className={'text-[9px] leading-[15px] font-bold text-white whitespace-nowrap'}
                                enablePreWhiteSpace={false}>
                                {t('accountPro.currentState.proBadge')}
                            </Text>
                        </div>
                    )}
                </div>
            ) : (
                <Span
                    className={`${
                        size === 'normal'
                            ? 'rounded-[5px] bg-gradient_pro px-[10px] py-[3px] text-body3 font-bold text-white whitespace-nowrap'
                            : 'rounded-[2px] bg-gradient_pro px-[5px] text-[9px] leading-[15px] font-bold text-white whitespace-nowrap'
                    } inline-block h-fit my-auto align-middle ${className}`}>
                    {t('accountPro.currentState.proBadge')}
                </Span>
            )}
        </>
    )
}

export default ProTag
