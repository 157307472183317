import Link from 'next/link'
import parse, {domToReact} from 'html-react-parser'
import {equalString, isNotEmpty} from '@util/strings'
import Span from '@component/Text/Span'
import React from 'react'
import he from 'he'

export interface IFuncItem {
    id: string
    func?: () => void
}

export function parseTagComponent(text, funcList?: IFuncItem[], isHoverStyle: boolean = true) {
    const decodedText = he.decode(text)
    const options = {
        replace: domNode => {
            if (domNode.name === 'a' && domNode.attribs.href) {
                return (
                    <Link
                        href={domNode.attribs.href}
                        onClick={e => {
                            e?.stopPropagation()
                        }}
                        target={domNode.attribs.target ?? domNode.attribs.target}
                        className={`underline break-words ${
                            isHoverStyle &&
                            'hover:text-primary_shade dark:hover:text-dark_primary_shade active:text-primary_shade dark:active:text-dark_primary_shade'
                        } ${domNode.attribs.class}`}>
                        {domToReact(domNode.children, options)}
                    </Link>
                )
            } else if (domNode.name === 'span') {
                const id = domNode.attribs?.id
                const onClick =
                    isNotEmpty(domNode.attribs.click_id) &&
                    funcList?.find(item => equalString(item?.id, domNode.attribs.click_id))
                        ? funcList?.find(item => equalString(item?.id, domNode.attribs.click_id))?.func
                        : undefined
                return (
                    <Span
                        id={id}
                        className={`${domNode.attribs?.class} ${onClick ? 'cursor-pointer' : ''}`}
                        onClick={onClick}>
                        {domToReact(domNode.children, options)}
                    </Span>
                )
            }
        },
    }

    return parse(decodedText, options)
}
