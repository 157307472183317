import React, {useImperativeHandle, useRef, useState} from 'react'
import {useTranslation} from '@lib/intl/useTranslations'
import WizButton from '@component/buttons/WizButton'
import WizContentModal from '@component/modal/WizContentModal'
import img_connect_exchange from '@image/img_connect_exchange.png'
import img_connect_exchange_dark from '@image/img_connect_exchnage_dark.png'
import useNavigation from '@hook/useNavigation'
import TagText from '@component/Text/TagText'
import WizImage from '@component/images/WizImage'
import useDark from '@hook/useDark'

type ScreenType = 'default' | 'copy' | 'leader'

interface IProps {}

const ExchangeConnectionModal = ({}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const t = useTranslation()
    const {goApi} = useNavigation()
    const {isRenderDark} = useDark()
    const [screen, setScreen] = useState<ScreenType>()

    useImperativeHandle(ref, () => ({
        show: ({type}: {type: ScreenType}) => {
            setScreen(type)
            modalRef.current.show()
        },
    }))

    return (
        <WizContentModal size={'600'} title={t('modal.exchange.connect.title')} ref={modalRef}>
            <div className={'flex flex-col items-center justify-center mt-[30px]'}>
                <WizImage src={isRenderDark ? img_connect_exchange_dark : img_connect_exchange} height={150} />
                <TagText className={'text-body1 text-gray02 dark:text-dark_gray02 text-center mt-[10px] break-words'}>
                    {screen === 'default'
                        ? t('modal.connect.exchange.desc')
                        : t(`modal.exchange.connect.${screen}.desc`)}
                </TagText>
                <WizButton
                    className={'w-full mt-[30px]'}
                    text={t('modal.exchange.connect.button.text')}
                    buttonType={'primary'}
                    onClick={goApi}
                />
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(ExchangeConnectionModal)
