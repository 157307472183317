import {useMemo} from 'react'
import {useTranslations as _useTranslations} from 'next-intl'
import {getMessageFallback} from '@lib/intl/intl'

type TWithFallbackKey = {
    (key: string): string
    (key: string, values: Record<string, unknown>): string
    (key: string, values: Record<string, unknown> | undefined, fallbackKey: string): string
}

/**
 * @description next-i18next -> next-intl 라이브러리 변경으로 번역 시트 처리를 위한 훅입니다.
 * 기존 방식 global.title 에서 '.'을 이용한 처리 방식이 새로운 라이브러리와 달라 '.'를 '_'로 바꿔주는(global_title) 처리를 합니다.
 * 추가적으로 defaultValue 값을 처리하기 위해 fallbackKey 값을 추가했습니다.
 * @see https://next-intl.dev/docs/usage/messages
 */
export const useTranslation = (...args: Parameters<typeof _useTranslations>) => {
    const t = _useTranslations(...args)

    return useMemo(() => {
        const proxy = new Proxy(t, {
            apply(target, thisArg, argumentsList: Parameters<TWithFallbackKey>) {
                const [key, valuesOrUndefined, fallbackKey] = argumentsList
                const replacedKey = key.replaceAll('.', '_')
                const fallback = typeof fallbackKey === 'string' ? fallbackKey.replaceAll('.', '_') : null

                const translated = (() => {
                    if (typeof valuesOrUndefined === 'object' && valuesOrUndefined !== null) {
                        return target?.apply(thisArg, [replacedKey, valuesOrUndefined])
                    }
                    return target?.apply(thisArg, [replacedKey])
                })()

                if (translated.startsWith(getMessageFallback({error: null, key: replacedKey}))) {
                    if (fallback) {
                        try {
                            return target?.apply(thisArg, [fallback])
                        } catch {
                            return getMessageFallback({error: null, key: fallback})
                        }
                    }
                }

                return translated
            },
        })

        return proxy as unknown as TWithFallbackKey
    }, [t])
}
