import React, {useContext, useMemo, useRef, useState} from 'react'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import Text from '@component/Text/Text'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useTranslation} from '@lib/intl/useTranslations'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import WizButton from '@component/buttons/WizButton'
import TagText from '@component/Text/TagText'
import {useNavigate} from 'react-router-dom'
import {FindPasswordContext} from '@feature/common/components/LoginModal/routes/FindPasswordRoutes/FindPasswordRoutes'
import {apiSendPasswordResetCode} from '@api/auths/findPassword/SendPasswordResetCode'
import useSnackbar from '@hook/useSnackbar'
import {isEmpty} from '@util/strings'
import {apiCheckResetPasswordCode} from '@api/auths/findPassword/checkResetPasswordCode'
import ResendCodeTimer from '@feature/common/components/LoginModal/component/ResendCodeTimer'
import {createLog} from '@util/logs'

interface IProps extends ILoginModalProps {}

const ScreenForgotPasswordCode: React.FC<IProps> = ({closeModal}) => {
    const t = useTranslation()
    const navigate = useNavigate()
    const {showErrorSnackbar} = useSnackbar()
    const {email, code, setCode} = useContext(FindPasswordContext)

    const [isLoading, setIsLoading] = useState(false)
    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT)
    const [inputErrorMessage, setInputErrorMessage] = useState('')

    const resendTimerRef = useRef<any>()

    const isDisableSubmitButton = useMemo(() => isEmpty(code), [code])

    const onClickResendCode = async () => {
        createLog('event', 'login_forgot_password_resend_email_code')
        resendTimerRef?.current?.startTimer()
        const {data, error} = await apiSendPasswordResetCode(email)
        if (!data) {
            createLog('error', 'login_forgot_password_resend_email_code_failed')
            showErrorSnackbar(error)
            resendTimerRef?.current?.stopTimer()
        }
    }

    const onClickSubmit = async () => {
        if (isDisableSubmitButton) return

        createLog('event', 'login_forgot_password_submit_email_code')

        setIsLoading(true)
        const {data, status, error} = await apiCheckResetPasswordCode(email, code)
        if (data) {
            navigate('/find_password_set_password')
        } else {
            if (status === 5005) {
                createLog('error', 'login_forgot_password_submit_email_code_failed_incorrect_code')
                setInputState(InputState.ERROR)
                setInputErrorMessage(t('loginModal.forgotPasswordCode.submit.res.fail.incorrectCode'))
            } else {
                createLog('error', 'login_forgot_password_submit_email_code_failed')
                showErrorSnackbar(error)
            }
        }

        setIsLoading(false)
    }

    return (
        <LoginModalLayout
            closeModal={closeModal}
            title={t('loginModal.forgotPasswordCode.page.title')}
            sideImageType={'safety_first'}>
            <TagText className={'text-body1 text-gray02 dark:text-dark_gray02 break-words'}>
                {t('loginModal.forgotPasswordCode.desc', {
                    email: email.length > 30 ? email.slice(0, 30) + '...' : email,
                })}
            </TagText>
            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                {t('loginModal.forgotPasswordCode.code.label')}
            </Text>
            <WizInputText
                text={code}
                onChanged={text => setCode(text)}
                state={inputState}
                autoFocus={true}
                errorDescription={inputErrorMessage}
                textType={'uppercase'}
                className={'mt-[5px]'}
                placeholder={t('loginModal.forgotPasswordCode.code.input.placeholder')}
                onPressEnter={onClickSubmit}
            />
            <WizButton
                buttonType={'primary'}
                text={t('loginModal.forgotPasswordCode.submit.continue.text')}
                className={'mt-[10px] w-full'}
                isLoading={isLoading}
                disabled={isDisableSubmitButton}
                onClick={onClickSubmit}
            />
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 text-center mt-[10px]'}>
                {t('loginModal.forgotPasswordCode.resend')}{' '}
                <ResendCodeTimer ref={resendTimerRef} initialTime={180} onClickTimerStopped={onClickResendCode} />
            </Text>
        </LoginModalLayout>
    )
}

export default ScreenForgotPasswordCode
