import React, {useContext, useEffect, useMemo, useState} from 'react'
import {ILoginModalProps, SetLoginStateContext} from '@feature/common/components/LoginModal/LoginModalRouter'
import Text from '@component/Text/Text'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useTranslation} from '@lib/intl/useTranslations'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import WizButton from '@component/buttons/WizButton'
import {NICKNAME_MAX_LENGTH, NICKNAME_MIN_LENGTH} from '@constant/auth'
import InputTextCount from '@component/inputs/InputTextCount'
import {useNavigate} from 'react-router-dom'
import {RegisterContext} from '@feature/common/components/LoginModal/routes/RegisterRoutes/RegisterRoutes'
import {isEmpty} from '@util/strings'
import {ENGLISH_NICKNAME_FORBIDDEN_REGEX} from '@constant/reg'
import {apiCheckUserName} from '@api/auths/register/checkUserName'
import useSnackbar from '@hook/useSnackbar'
import {createLog} from '@util/logs'
import {useRouter} from 'next/router'
import IconArrowDown from '@svg/common/ic_btn_down.svg'
import TagText from '@component/Text/TagText'
import {apiEditReferral} from '@api/auths/register/editReferral'
import {apiCheckReferral} from '@api/auths/register/checkReferral'
import {apiEditName} from '@api/user/account/editName'
import {LoginModalContext} from '@pages/login/index.page'
import {ILoginInfoData} from '@store/LoginStore'
import {apiFetchMe} from '@api/user/account/me'
import {LoginNetwork} from '@type/profile'
import {getLinkUrl, URL_PORTFOLIO} from '@constant/url'

interface IProps extends ILoginModalProps {}

const ScreenRegisterSocialReferral: React.FC<IProps> = ({closeModal}) => {
    const t = useTranslation()
    const navigate = useNavigate()
    const router = useRouter()

    const [isLoading, setIsLoading] = useState(false)
    const {showErrorSnackbar, showSnackbar} = useSnackbar()

    const {name, setName, referral, setReferral} = useContext(RegisterContext)
    const {appliedReferral} = useContext(LoginModalContext)
    const {refCode} = useContext(SetLoginStateContext)

    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT)
    const [referralInputState, setReferralInputState] = useState<InputState>(InputState.DEFAULT)
    const [inputErrorMessage, setInputErrorMessage] = useState('')
    const [referralInputErrorMessage, setReferralInputErrorMessage] = useState('')
    const [isOpenReferral, setIsOpenReferral] = useState(false)

    const isDisableSubmitButton = useMemo(
        () =>
            isEmpty(name) ||
            name?.length < NICKNAME_MIN_LENGTH ||
            name?.length > NICKNAME_MAX_LENGTH ||
            inputState === InputState.ERROR ||
            referralInputState === InputState.ERROR,
        [name, inputState, referralInputState],
    )

    const createCompleteFunc = async () => {
        const {data: nameData, error: nameError} = await apiEditName(name)
        if (nameError) {
            showErrorSnackbar(nameError)
            createLog('error', 'login_social_register_name_edit_failed', {error: nameError})
            return
        }

        const {data: me, error: errorMe} = await apiFetchMe()

        const prevAccount: ILoginInfoData = JSON.parse(localStorage.getItem('user_accounts'))?.find(
            item => item?.id === me?.id,
        )

        const accountsFilterArr: ILoginInfoData[] = JSON.parse(localStorage.getItem('user_accounts'))?.filter(
            item => item?.id !== me?.id,
        )
        localStorage.setItem(
            'user_accounts',
            JSON.stringify([
                ...(Array.isArray(accountsFilterArr) ? accountsFilterArr : []),
                {
                    ...prevAccount,
                    name: name,
                },
            ]),
        )

        if ((me?.network === LoginNetwork.OKX || me?.network === LoginNetwork.BINANCE) && me?.keys?.length > 0) {
            closeModal()
            void router?.replace(getLinkUrl(URL_PORTFOLIO(me?.id)))
        } else {
            navigate('/register_done')
        }

        createLog('event', 'login_social_register_done')
    }

    const onClickSubmit = async () => {
        if (isDisableSubmitButton) return
        createLog('event', 'login_email_register_name_submit')

        setIsLoading(true)
        const {data, error} = await apiCheckUserName(name)
        if (data) {
            if (data?.can_use === true) {
                // Referral Code 입력시
                if (referral && (appliedReferral ? !appliedReferral : !refCode)) {
                    const {data: referralRes, error: referralResError} = await apiCheckReferral(referral)
                    if (referralResError) {
                        createLog('error', 'login_social_register_referral_check_failed', {
                            error: referralResError,
                        })
                        showErrorSnackbar(referralResError)
                        return
                    }

                    // Referral Code가 유효할 때
                    if (referralRes?.is_valid) {
                        const {
                            data: referralData,
                            status: referralCode,
                            error: referralError,
                        } = await apiEditReferral(referral)
                        if (referralData) {
                            await createCompleteFunc()
                        } else {
                            if (referralCode === 5017) {
                                createLog('error', 'login_email_register_password_submit_failed_wrong_code', {
                                    error: referralError,
                                })
                                setReferralInputErrorMessage(t('loginModal.registerReferral.submit.res.fail.wrongCode'))
                            } else {
                                createLog('error', 'login_email_register_password_submit_failed', {
                                    error: referralError,
                                })
                                showErrorSnackbar(referralError)
                            }
                            setReferralInputState(InputState.ERROR)
                            return
                        }
                    }

                    // Referral Code가 유효하지 않을 때
                    else {
                        setReferralInputState(InputState.ERROR)
                        setReferralInputErrorMessage(t('loginModal.registerReferral.submit.res.fail.wrongCode'))
                    }

                    // Referral Code가 없을 때
                } else {
                    await createCompleteFunc()
                }
            } else {
                setInputState(InputState.ERROR)
                setInputErrorMessage(t('loginModal.registerName.submit.res.fail.nameDuplication'))
                createLog('error', 'login_email_register_name_submit_failed_nameDuplication')
            }
        } else {
            showErrorSnackbar(error)
            createLog('error', 'login_email_register_name_submit_failed')
        }
        setIsLoading(false)
    }

    const onChangedInputText = (text?: string) => {
        setName(text)
        if (ENGLISH_NICKNAME_FORBIDDEN_REGEX.test(text)) {
            setInputState(InputState.ERROR)
        } else {
            setInputErrorMessage(t('loginModal.registerName.name.input.description'))
            setInputState(InputState.DEFAULT)
        }
    }

    const onChangedInputReferral = (code?: string) => {
        setReferral(code)
        setReferralInputState(InputState.DEFAULT)
    }

    useEffect(() => {
        if (router?.query?.referral) {
            setReferral(router?.query?.referral as string)
        }
    }, [router?.query])

    return (
        <LoginModalLayout
            closeModal={() => {
                closeModal()
            }}
            title={t('loginModal.social.registerName.page.title')}
            hideBackButton={true}>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 break-words'}>
                {t('loginModal.registerName.desc')}
            </Text>
            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                {t('loginModal.registerName.name.label')}
            </Text>
            <WizInputText
                text={name}
                className={'mt-[5px]'}
                state={inputState}
                autoFocus={true}
                errorDescription={inputErrorMessage}
                placeholder={t('loginModal.registerName.name.input.placeholder')}
                defaultDescription={t('loginModal.registerName.name.input.description')}
                renderInputRightArea={
                    <InputTextCount text={name} maxCount={NICKNAME_MAX_LENGTH} minLength={NICKNAME_MIN_LENGTH} />
                }
                onChanged={onChangedInputText}
                onPressEnter={onClickSubmit}
            />

            {!router?.query?.referral && (
                <div className={'mt-[20px]'}>
                    <div
                        className={'flex items-center gap-[5px] cursor-pointer'}
                        onClick={() => {
                            setIsOpenReferral(prev => !prev)
                        }}>
                        <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                            {t('loginModal.registerName.referral.title')}
                        </Text>
                        <IconArrowDown
                            className={`w-[16px] fill-gray02 dark:fill-dark_gray02 ${
                                isOpenReferral && 'rotate-[180deg]'
                            }`}
                        />
                    </div>
                    {isOpenReferral && (
                        <div>
                            <WizInputText
                                text={referral}
                                className={'mt-[5px]'}
                                state={referralInputState}
                                errorDescription={referralInputErrorMessage}
                                placeholder={t('loginModal.registerName.referral.placeholder')}
                                onChanged={onChangedInputReferral}
                                onPressEnter={onClickSubmit}
                            />
                            <ul className={'mt-[5px] list-disc list-outside pl-[15px]'}>
                                <li className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                    <TagText>{t('loginModal.registerName.referral.desc1')}</TagText>
                                </li>
                                <li className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                    <TagText>{t('loginModal.registerName.referral.desc2')}</TagText>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            )}
            <WizButton
                buttonType={'primary'}
                text={t('loginModal.registerName.submit.text')}
                disabled={isDisableSubmitButton}
                className={'mt-[20px] w-full'}
                onClick={onClickSubmit}
                isLoading={isLoading}
            />
        </LoginModalLayout>
    )
}

export default ScreenRegisterSocialReferral
