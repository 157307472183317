import {getTranslations as _getTranslations} from 'next-intl/server'
import {IntlError} from 'use-intl'

/**
 * @description 설정된 언어 기반 번역 시트 로드하는 함수
 * @param locale - 'en' | 'ko'
 * @returns {function} - 번역 시트 리턴
 */
export async function getMessages(locale?: string) {
    try {
        const messages = await import(`../../../public/locales/${locale || 'en'}/common.json`)
        return messages.default
    } catch (error) {
        console.error('Error loading messages:', error)
        return {}
    }
}

/**
 * @description 서버에서 설정된 언어 기반 번역 문구 불러오는 함수
 * @returns {function} - 번역 문구 리턴
 */
export async function getTranslationsProxy(locale: string, namespace?: string) {
    const t = await _getTranslations({locale, namespace})

    return new Proxy(t, {
        apply(target, thisArg, [key, ...rest]) {
            const replacedKey = key.replaceAll('.', '_')
            return target.apply(thisArg, [replacedKey, ...rest] as Parameters<typeof t>)
        },
    }) as typeof t
}

/**
 * @description next-intl 에러 처리 함수
 * @returns {function} - 키 값을 못 찾을 경우 리턴 이외 모든 에러 던짐
 */
export async function errorHandler(error: IntlError) {
    if (error.code === 'MISSING_MESSAGE') return
    throw error
}

/**
 * @description next-intl 에러 발생 시 해당 키 리턴하는 함수
 * @returns {function} - 키 값 리턴
 */
export function getMessageFallback(info: {error: IntlError; key: string; namespace?: string}) {
    if (process.env.NODE_ENV === 'development') return `[❗️MISSING: ${info.key}]`
    return '' //`__missing__:${info.key}`
}
