import '@style/globals.css'
import '@style/tailwind.css'
import '@style/colorStyle.css'
import '@style/reset.css'
import '@style/event/event.css'
import '@font/createGlobalStyle.css'
import 'react-toastify/dist/ReactToastify.css'

import React, {useState} from 'react'
import type {AppProps} from 'next/app'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import {useRouter} from 'next/router'
import {NextIntlClientProvider} from 'next-intl'
import {ToastContainer} from 'react-toastify'
import {Hydrate, QueryClient, QueryClientProvider} from '@tanstack/react-query'

import {errorHandler, getMessageFallback} from '@lib/intl/intl'

import Loader from '@component/core/Loader'
import ModalContainer from '@component/core/ModalContainer'
import LoginExtender from '@component/core/LoginExtender'
import LotAppBanner from '@component/appBanner/LotAppBanner'
import PresetLoader from '@component/core/PresetLoader'
import PageLayout from '@util/layout'

const DataDogIdentification = dynamic(() => import('@component/core/DataDogIdentification'), {ssr: false})
const GoogleAnalytics = dynamic(() => import('@component/google/GoogleAnalytics'), {ssr: false})

const MyApp = (props: AppProps) => {
    const router = useRouter()
    const [queryClient] = useState(() => new QueryClient())

    return (
        <NextIntlClientProvider
            timeZone={router.locale === 'ko' ? 'Asia/Seoul' : 'UTC'}
            locale={router.locale}
            messages={props.pageProps.messages}
            onError={errorHandler}
            getMessageFallback={getMessageFallback}>
            <Head>
                <meta name="apple-itunes-app" content="app-id=1462023165" />
            </Head>
            <GoogleAnalytics />
            <LoginExtender />
            <QueryClientProvider client={queryClient}>
                <Hydrate state={props.pageProps.dehydratedState}>
                    <>
                        <Loader />
                        <ModalContainer />
                        <PresetLoader />
                        <LotAppBanner />
                        <PageLayout {...props} />
                        <ToastContainer
                            position={'bottom-center'}
                            hideProgressBar={true}
                            autoClose={3000}
                            pauseOnFocusLoss={false}
                            toastClassName={'!bg-transparent !shadow-none !p-0 !m-0 !h-min max-w-[400px] w-full !fixed'}
                            bodyClassName={'text-white !p-0 !m-0 !h-min'}
                            className={'!w-full max-w-[400px] min-h-[54px] !p-0 !bottom-[50px] !z-snackbar'}
                            closeButton={false}
                        />
                    </>
                </Hydrate>
                <DataDogIdentification />
            </QueryClientProvider>
        </NextIntlClientProvider>
    )
}

export default MyApp
