import React, {useImperativeHandle} from 'react'
import useTimer from '@hook/useTimer'
import Span from '@component/Text/Span'
import {useTranslation} from '@lib/intl/useTranslations'
import TagSpan from '@component/Text/TagSpan'

interface IProps {
    initialTime: number
    className?: string
    onStarted?: () => void
    onEnded?: () => void
    onClickTimerStopped?: () => void
}

const ResendCodeTimer = ({initialTime, className, onStarted, onEnded, onClickTimerStopped}: IProps, ref: any) => {
    const t = useTranslation()

    const {timeLeft, startTimer, stopTimer} = useTimer({
        onStarted: onStarted,
        onEnded: onEnded,
    })

    useImperativeHandle(ref, () => ({
        startTimer: () => {
            startTimer(initialTime)
        },
        stopTimer: () => {
            stopTimer()
        },
    }))

    const formatTimeLeft = (time: number) => {
        let timeSec = Math.floor(time % 60)
        let timeMin = Math.floor(time / 60)

        let minutes = '00'
        if (timeMin < 10) {
            minutes = `0${timeMin}`
        } else {
            minutes = `${timeMin}`
        }

        let seconds = '00'
        if (timeSec < 10) {
            seconds = `0${timeSec}`
        } else {
            seconds = `${timeSec}`
        }

        return minutes + ':' + seconds
    }

    if (timeLeft === 0) {
        return (
            <Span
                className={`${className} cursor-pointer text-primary_shade dark:text-dark_primary_shade underline`}
                onClick={onClickTimerStopped}>
                {t('loginModal.resendCodeTimer.stopped')}
            </Span>
        )
    } else {
        return (
            <TagSpan className={`${className}`}>
                {t('loginModal.resendCodeTimer.running', {time: formatTimeLeft(timeLeft)})}
            </TagSpan>
        )
    }
}

export default React.forwardRef(ResendCodeTimer)
