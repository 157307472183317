import React from 'react'
import Head from 'next/head'
import {isNotEmpty} from '@util/strings'
import {IMetaTags} from '@component/layouts/PageConfig/PageConfig'
import {useTranslation} from '@lib/intl/useTranslations'

interface IProps {
    className?: string
    metaTags?: IMetaTags
}

const HeadContainer: React.FC<IProps> = ({className, metaTags}) => {
    const t = useTranslation()
    return (
        <Head>
            <meta
                name="title"
                content={isNotEmpty(metaTags?.title) ? metaTags?.title : t('metaTag.title')}
                key={'title'}
            />
            <meta
                name="description"
                content={isNotEmpty(metaTags?.description) ? metaTags?.description : t('metaTag.description')}
                key={'desc'}
            />
            <meta name={'keywords'} content={'코인, 비트코인, 암호화폐, 이더리움, Bitcoin'} />
            <meta name={'news_keywords'} content={'코인, 비트코인, 암호화폐, 이더리움, Bitcoin'} />
            <meta
                name={'viewport'}
                content={'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no'}
            />
            <meta name={'robots'} content={'index, follow'} />
            <meta name={'og:type'} content={'website'} />
            <meta property="og:title" content={isNotEmpty(metaTags?.title) ? metaTags?.title : t('metaTag.title')} />
            <meta
                property="og:description"
                content={isNotEmpty(metaTags?.description) ? metaTags?.description : t('metaTag.description')}
            />
            <meta
                property="og:image"
                content={
                    isNotEmpty(metaTags?.image)
                        ? metaTags?.image
                        : 'https://leagueoftraders.io/images/img_meta_thumbnail.png'
                }
            />
            <meta
                itemProp={'image'}
                content={
                    isNotEmpty(metaTags?.image)
                        ? metaTags?.image
                        : 'https://leagueoftraders.io/images/img_meta_thumbnail.png'
                }
            />

            <meta
                name="thumbnail"
                content={
                    isNotEmpty(metaTags?.image)
                        ? metaTags?.image
                        : 'https://leagueoftraders.io/images/img_meta_thumbnail.png'
                }
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@leagueoftraders_io" />
            <meta name="twitter:creator" content="@leagueoftraders_io" />
            <meta name="twitter:title" content={isNotEmpty(metaTags?.title) ? metaTags?.title : t('metaTag.title')} />
            <meta
                name="twitter:description"
                content={isNotEmpty(metaTags?.description) ? metaTags?.description : t('metaTag.description')}
            />
            <meta
                name="twitter:image"
                content={
                    isNotEmpty(metaTags?.image)
                        ? metaTags?.image
                        : 'https://leagueoftraders.io/images/img_meta_thumbnail.png'
                }
            />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
        </Head>
    )
}
export default HeadContainer
