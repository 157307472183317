import React, {useCallback, useMemo, useRef} from 'react'

import {useTranslation} from '@lib/intl/useTranslations'

import {isNotEmptyArray} from '@util/array'

import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'

import ExchangeConnectionModal from '@feature/portfolio/components/ExchangeConnectionModal/ExchangeConnectionModal'

import {createLog} from '@util/logs'
import {getLinkUrl, URL_COPY_PORTFOLIO, URL_LEADER_PORTFOLIO, URL_PORTFOLIO} from '@constant/url'
import {Tab} from '@component/tabs/WizNavTabs'
import {useRouter} from 'next/router'
import WizPortfolioNavTabs from '@component/tabs/WizPortfolioNavTabs'

interface IProps {
    className?: string
    id: string
}

const PortfolioNavigation: React.FC<IProps> = ({className, id}) => {
    const t = useTranslation()

    const router = useRouter()

    const exchangeConnectionModalRef = useRef<any>()

    const {data: me} = useQueryFetchMe(undefined, false)
    const hasExchangeKeys = useMemo(() => isNotEmptyArray(me?.keys), [me?.keys])

    const onClickOpenExchangeConnectModal = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, type: 'copy' | 'leader') => {
            if (!hasExchangeKeys) {
                createLog('event', 'portfolio_connect_exchange', {type: type})
                event?.preventDefault()
                exchangeConnectionModalRef.current?.show({type: type})
                return
            }
            createLog('event', 'portfolio_click_navigation_tab', {type: type})
        },
        [hasExchangeKeys],
    )

    const TABS: Tab[] = useMemo(() => {
        return [
            {
                id: [
                    '/user/[id]/portfolio',
                    '/key/[id]/portfolio',
                    '/key/[id]/copyfolio',
                    '/key/[id]/leaderfolio',
                    '/key/[id]/history',
                    '/key/[id]/pnl',
                    '/key/[id]/open-order',
                ],
                label: t('portfolio.menu.portfolio'),
                link: getLinkUrl(URL_PORTFOLIO(id)),
            },
            {
                id: ['/user/[id]/copyfolio', '/user/[id]/copyfolio/history', '/bot/[id]/pnl'],
                label: t('portfolio.menu.copy'),
                link: getLinkUrl(URL_COPY_PORTFOLIO(id)),
                onClick: event => onClickOpenExchangeConnectModal(event, 'copy'),
            },
            {
                id: [
                    '/user/[id]/leaderfolio',
                    '/user/[id]/leaderfolio/payment',
                    '/key/[id]/leaderfolio/pnl',
                    '/key/[id]/leaderfolio/history',
                    '/key/[id]/leaderfolio/copiers',
                    '/key/[id]/leaderfolio/orders',
                ],
                label: t('portfolio.menu.leader'),
                link: getLinkUrl(URL_LEADER_PORTFOLIO(id)),
                onClick: event => onClickOpenExchangeConnectModal(event, 'leader'),
            },
        ]
    }, [t, id, onClickOpenExchangeConnectModal])

    return (
        <div className={'mb-[20px]'}>
            <WizPortfolioNavTabs type={'links'} tabs={TABS} selectedTab={router?.pathname} />
            <ExchangeConnectionModal ref={exchangeConnectionModalRef} />
        </div>
    )
}
export default React.memo(PortfolioNavigation)
