import React, {useContext, useRef, useState} from 'react'
import {ILoginModalProps, SetLoginStateContext} from '@feature/common/components/LoginModal/LoginModalRouter'
import Text from '@component/Text/Text'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useTranslation} from '@lib/intl/useTranslations'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import WizButton from '@component/buttons/WizButton'
import TagText from '@component/Text/TagText'
import {useNavigate} from 'react-router-dom'
import {RegisterContext} from '@feature/common/components/LoginModal/routes/RegisterRoutes/RegisterRoutes'
import {isEmpty} from '@util/strings'
import {apiSendEmailConfirmCode} from '@api/auths/register/sendEmailConfirmCode'
import useSnackbar from '@hook/useSnackbar'
import {apiEmailConfirmCode} from '@api/auths/register/confirmEmailCode'
import {useLoginStore} from '@store/LoginStore'
import ResendCodeTimer from '@feature/common/components/LoginModal/component/ResendCodeTimer'
import {LoginModalContext} from '../../../../../../pages/login/index.page'
import {createLog} from '@util/logs'
import {apiEditReferral} from '@api/auths/register/editReferral'

interface IProps extends ILoginModalProps {}

const ScreenRegisterEmailConfirm: React.FC<IProps> = ({closeModal}) => {
    const t = useTranslation()
    const navigate = useNavigate()

    const {email, name, code, setCode, referral} = useContext(RegisterContext)
    const login = useLoginStore(state => state.login)
    const {showErrorSnackbar} = useSnackbar()
    const [isLoading, setIsLoading] = useState(false)

    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT)
    const [inputErrorMessage, setInputErrorMessage] = useState('')

    const resendTimerRef = useRef<any>()
    const {appliedReferral} = useContext(LoginModalContext)
    const {refCode} = useContext(SetLoginStateContext)

    const onClickSendCode = async () => {
        createLog('event', 'login_email_register_code_submit')
        resendTimerRef?.current?.startTimer()
        const {data, status, error} = await apiSendEmailConfirmCode(email)
        if (!data) {
            createLog('error', 'login_email_register_code_submit_failed')
            showErrorSnackbar(error)
            resendTimerRef?.current?.stopTimer()
        }
    }

    const onClickSubmit = async () => {
        createLog('event', 'login_email_register_code_confirm')
        setIsLoading(true)
        const {data, status, error} = await apiEmailConfirmCode(email, name, code, appliedReferral || refCode)
        if (data) {
            login(data?.user_id, data?.access_token)

            if (referral && (appliedReferral ? !appliedReferral : !refCode)) {
                queueMicrotask(async () => {
                    createLog('event', 'login_email_register_referral_submit')

                    const {
                        data: referralData,
                        status: referralCode,
                        error: referralError,
                    } = await apiEditReferral(referral)
                    if (referralData) {
                        navigate('/register_password')
                    } else {
                        if (referralCode === 5017) {
                            createLog('error', 'login_email_register_password_submit_failed_wrong_code')
                            showErrorSnackbar(referralError)
                        } else {
                            createLog('error', 'login_email_register_password_submit_failed')
                            showErrorSnackbar(referralError)
                        }
                    }
                })
            } else {
                navigate('/register_password')
            }
        } else {
            if (status === 5005) {
                createLog('error', 'login_email_register_code_submit_failed_incorrect_code')
                setInputState(InputState.ERROR)
                setInputErrorMessage(t('loginModal.registerCode.submit.res.fail.incorrectCode'))
            } else {
                createLog('error', 'login_email_register_code_submit_failed')
                setInputState(InputState.ERROR)
                setInputErrorMessage(error)
            }
        }
        setIsLoading(false)
    }

    return (
        <LoginModalLayout closeModal={closeModal} title={t('loginModal.registerCode.page.title')}>
            <TagText className={'text-body1 text-gray02 dark:text-dark_gray02 break-words'}>
                {t('loginModal.registerCode.desc', {email: email.length > 30 ? email.slice(0, 30) + '...' : email})}
            </TagText>
            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                {t('loginModal.registerCode.code.label')}
            </Text>
            <WizInputText
                className={'mt-[5px]'}
                text={code}
                state={inputState}
                errorDescription={inputErrorMessage}
                onChanged={text => {
                    setCode(text)
                    setInputState(InputState.DEFAULT)
                }}
                textType={'uppercase'}
                autoFocus={true}
                placeholder={t('loginModal.registerCode.code.input.placeholder')}
                onPressEnter={onClickSubmit}
            />
            <WizButton
                disabled={isEmpty(code)}
                isLoading={isLoading}
                buttonType={'primary'}
                text={t('loginModal.registerCode.submit.text')}
                className={'mt-[20px] w-full'}
                onClick={onClickSubmit}
            />
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 text-center mt-[10px]'}>
                {t('loginModal.registerCode.resend')}{' '}
                <ResendCodeTimer ref={resendTimerRef} initialTime={180} onClickTimerStopped={onClickSendCode} />
            </Text>
        </LoginModalLayout>
    )
}

export default ScreenRegisterEmailConfirm
