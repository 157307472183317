import {useMemo} from 'react'
import {useTranslation} from '@lib/intl/useTranslations'

export const PasswordSecureTypes = {
    NONE: 'none',
    WEAK: 'weak',
    MEDIUM: 'medium',
    STRONG: 'strong',
} as const
export type PasswordSecureType = (typeof PasswordSecureTypes)[keyof typeof PasswordSecureTypes]

type PasswordCheckData = {
    progress: number
    progressStyle: string
    secureTextStyle: string
    secureText: string
}

export const usePasswordSecure = (passwordSecure: PasswordSecureType): PasswordCheckData => {
    const t = useTranslation()
    const checkedPasswordData = useMemo(() => {
        let progress = 0
        let progressStyle = 'bg-gray06 dark:bg-dark_gray06'
        let secureTextStyle = 'text-gray02 dark:text-dark_gray02'
        let secureText = ''
        switch (passwordSecure) {
            case PasswordSecureTypes.NONE:
                secureText = t('loginModal.password.secure.none.text')
                break
            case PasswordSecureTypes.WEAK:
                progress = 30
                progressStyle = 'bg-red dark:bg-dark_red'
                secureTextStyle = 'text-red_shade dark:text-dark_red_shade'
                secureText = t('loginModal.password.secure.weak.text')
                break
            case PasswordSecureTypes.MEDIUM:
                progress = 70
                progressStyle = 'bg-blue dark:bg-dark_blue'
                secureTextStyle = 'text-blue dark:text-dark_blue'
                secureText = t('loginModal.password.secure.medium.text')
                break
            case PasswordSecureTypes.STRONG:
                progress = 100
                progressStyle = 'bg-primary dark:bg-dark_primary'
                secureTextStyle = 'text-primary dark:text-dark_primary'
                secureText = t('loginModal.password.secure.strong.text')
                break
        }
        return {
            progress,
            progressStyle,
            secureTextStyle,
            secureText,
        } as PasswordCheckData
    }, [passwordSecure, t])
    return checkedPasswordData
}
