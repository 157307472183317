import React, {useContext, useRef, useState} from 'react'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import Text from '@component/Text/Text'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import TagText from '@component/Text/TagText'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import WizButton from '@component/buttons/WizButton'
import {useTranslation} from '@lib/intl/useTranslations'
import {apiSendLoginCode} from '@api/auths/login/sendCode'
import {LoginContext} from '@feature/common/components/LoginModal/routes/LoginRoutes/LoginRoutes'
import {apiLoginEmailWithCode} from '@api/auths/login/apiLoginEmailWithCode'
import {useLoginStore} from '@store/LoginStore'
import useSnackbar from '@hook/useSnackbar'
import {isEmpty} from '@util/strings'
import ResendCodeTimer from '@feature/common/components/LoginModal/component/ResendCodeTimer'
import useLoginModal from '@feature/common/components/LoginModal/hooks/useLoginModal'
import {createLog} from '@util/logs'

interface IProps extends ILoginModalProps {}

const ScreenLoginWithCode: React.FC<IProps> = ({closeModal}) => {
    const t = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const login = useLoginStore(state => state.login)
    const {showErrorSnackbar} = useSnackbar()
    const {redirectAfterLogin} = useLoginModal()

    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT)
    const [inputErrorMessage, setInputErrorMessage] = useState('')

    const resendTimerRef = useRef<any>()

    const onClickSendCode = async () => {
        createLog('event', 'login_email_code_send')
        resendTimerRef?.current?.startTimer()
        const {data, error} = await apiSendLoginCode(email)
        if (!data) {
            showErrorSnackbar(error)
            resendTimerRef?.current?.stopTimer()
            createLog('error', 'login_email_code_send_failed')
        }
    }

    const onClickSubmit = async () => {
        createLog('event', 'login_email_code_submit')
        setIsLoading(true)
        const {data, status, error} = await apiLoginEmailWithCode(email, code)
        if (data) {
            login(data?.user_id, data?.access_token)
            closeModal()
            redirectAfterLogin()
        } else {
            if (status === 5005) {
                createLog('error', 'login_email_submit_failed_incorrect_code')
                setInputState(InputState.ERROR)
                setInputErrorMessage(t('loginModal.loginWithCode.submit.res.fail.incorrectCode'))
            } else {
                createLog('error', 'login_email_code_submit_failed')
                setInputState(InputState.ERROR)
                setInputErrorMessage(error)
            }
        }

        setIsLoading(false)
    }

    const {email, code, setCode} = useContext(LoginContext)
    return (
        <LoginModalLayout closeModal={closeModal} title={t('loginModal.loginWithCode.page.title')}>
            <TagText className={'text-body1 text-gray02 dark:text-dark_gray02 break-words'}>
                {t('loginModal.loginWithCode.desc', {email: email.length > 30 ? email.slice(0, 30) + '...' : email})}
            </TagText>
            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                {t('loginModal.loginWithCode.code.label')}
            </Text>
            <WizInputText
                className={'mt-[5px]'}
                placeholder={t('loginModal.loginWithCode.code.input.placeholder')}
                text={code}
                state={inputState}
                textType={'uppercase'}
                autoFocus={true}
                errorDescription={inputErrorMessage}
                onChanged={text => setCode(text)}
                onPressEnter={onClickSubmit}
            />
            <WizButton
                buttonType={'primary'}
                text={t('loginModal.loginWithCode.submit.continue.text')}
                className={'mt-[10px] w-full'}
                onClick={onClickSubmit}
                disabled={isEmpty(code)}
                isLoading={isLoading}
            />
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 text-center mt-[10px]'}>
                {t('loginModal.loginWithCode.resend')}{' '}
                <ResendCodeTimer ref={resendTimerRef} initialTime={180} onClickTimerStopped={onClickSendCode} />
            </Text>
        </LoginModalLayout>
    )
}

export default ScreenLoginWithCode
