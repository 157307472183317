import {useRouter} from 'next/router'
import {DarkModeStore} from '@store/DarkmodeStore'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'

const useInitDarkMode = () => {
    const {isDark, setCheckDark} = DarkModeStore()
    const router = useRouter()
    useIsomorphicLayoutEffect(() => {
        document.getElementsByTagName('body')[0].className = 'bg-white dark:bg-bg_dark_background'

        if (
            router?.pathname === '/home' ||
            router?.pathname === '/' ||
            router?.pathname === '/token' ||
            router?.pathname === '/meme' ||
            router?.pathname === '/landing/pro'
        ) {
            setCheckDark(true)
            document.documentElement.classList.add('dark')
            return
        }
        if (isDark) {
            if (router?.pathname === '/auto-trading/bithumb-popup' || router?.pathname?.includes('/event')) {
                setCheckDark(false)
                document.documentElement.classList.remove('dark')
            } else {
                setCheckDark(true)
                document.documentElement.classList.add('dark')
            }
        } else {
            if (router?.pathname === '/auto-trading/bithumb-popup' || router?.pathname?.includes('/event')) {
                setCheckDark(false)
            } else {
                setCheckDark(true)
            }
            document.documentElement.classList.remove('dark')
        }
    }, [router, isDark, setCheckDark])

    return null
}
export default useInitDarkMode
