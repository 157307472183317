import {ISnackbarAction, useSnackbarStore} from '@store/SnackbarStore'
import {useTranslation} from '@lib/intl/useTranslations'

const useSnackbar = () => {
    const t = useTranslation()
    const setSnackbarItem = useSnackbarStore(state => state.setSnackbarItem)

    const showSnackbar = (text: string, type?: ISnackbarAction) => {
        setSnackbarItem(text, type ? {text: type?.text, action: type?.action} : undefined)
    }

    const showErrorSnackbar = (errorText: string = '', type?: ISnackbarAction) => {
        if (errorText && errorText.replace(/(\s*)/g, '').length > 0) {
            showSnackbar(errorText, type)
        } else {
            showSnackbar(t('error.code.default'), type)
        }
    }

    return {showSnackbar, showErrorSnackbar}
}

export default useSnackbar
