import React, {useCallback, useContext, useImperativeHandle, useMemo, useRef, useState} from 'react'
import WizPopupModal from '@component/modal/WizPopupModal'
import {useTranslation} from '@lib/intl/useTranslations'
import Text from '@component/Text/Text'
import {useRouter} from 'next/router'
import Span from '@component/Text/Span'
import useFormatNumber from '@hook/useFormatNumber'
import {getNumberColorClass} from '@util/etc'
import img_logo from '@image/lot_logo.png'
import img_logo_dark from '@image/lot_logo_white.png'
import WizImage from '@component/images/WizImage'
import WizButton from '@component/buttons/WizButton'
import useNavigation from '@hook/useNavigation'
import {domToBlob, domToJpeg} from 'modern-screenshot'
import useFormatDate from '@hook/useFormatDate'
import useSnackbar from '@hook/useSnackbar'
import useQueryGetDailyReport from '@hook/query/user/account/report/useQueryGetDailyReport'
import {useLoginStore} from '@store/LoginStore'
import {createLog} from '@util/logs'
import {ModalContext} from '@component/core/ModalContainer'
import {apiUploadImage} from '@api/core/image/uploadImage'
import {URL_TIMELINE_POST_WITH_DAILY_REPORT, URL_TIMELINE_WITH_DAILY_REPORT} from '@constant/url'
import useWindowSize from '@hook/useWindowSize'

interface IProps {
    className?: string
    type?: 'print' | 'loader'
    onSaveCallBack?: (image_url: string) => void
}

const DailyReportModal = ({className, type = 'print', onSaveCallBack}: IProps, ref: any) => {
    const t = useTranslation()
    const {displayBalance, displayPercent} = useFormatNumber()
    const {goUserPortfolio} = useNavigation()
    const {formattedUTCOffset} = useFormatDate()
    const {showSnackbar} = useSnackbar()

    const modalRef = useRef<any>()
    const divRef = useRef<any>()

    const router = useRouter()

    const {isSm} = useWindowSize()

    const userId = useLoginStore(state => state.id)

    const [reportId, setReportId] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)

    const {setIsShowDailyReport} = useContext(ModalContext)

    const {data} = useQueryGetDailyReport(reportId, userId)

    useImperativeHandle(ref, () => ({
        show: (id?: string) => {
            modalRef.current.show()
            id && setReportId(id)
            if (type === 'print') return
            setTimeout(() => {
                void onSaveDailyReportAsURL()
            }, 50)
        },
        close: () => {
            modalRef.current?.close()
        },
    }))

    const getNumberHighLightColor = useMemo(() => {
        if (!data) return
        if (data?.earning === 0) {
            return 'bg-gray07 dark:bg-dark_gray07'
        }
        if (data?.earning >= 0) {
            return 'bg-primary_tint01 dark:bg-dark_primary_tint01'
        } else {
            return 'bg-red_tint01 dark:bg-dark_red_tint01'
        }
    }, [data])

    const onSaveDailyReportAsURL = async () => {
        const dataUrl = await domToBlob(divRef.current, {
            scale: 2,
            height: divRef?.current?.offsetHeight,
            width: divRef?.current?.offsetWidth,
            features: {removeControlCharacter: false},
        })
        const {success, image_url} = await apiUploadImage(dataUrl as File)
        if (success) {
            onSaveCallBack?.(image_url)
            modalRef.current?.close()
        }
    }

    const onSaveDailyReportAsJpeg = async () => {
        if (!divRef.current) return

        const dataUrl = await domToJpeg(divRef.current, {
            scale: 2,
            height: divRef?.current?.offsetHeight,
            width: divRef?.current?.offsetWidth,
            features: {removeControlCharacter: false},
        })

        const link = document.createElement('a')
        link.download = `Daily Report_${new Date().getTime()}`
        link.href = dataUrl
        link.click()
    }

    const onClickSaveDailyReport = async () => {
        if (isLoading) return

        setIsLoading(true)
        const stf = setTimeout(async () => {
            await onSaveDailyReportAsJpeg()
            setIsLoading(false)
        }, 1000)

        return () => {
            clearTimeout(stf)
        }
    }

    const renderUserProfitText = useCallback(() => {
        return `${displayBalance(data?.earning, {
            showPreSign: true,
            isShowMinusSymbol: false,
        })}(${data?.score >= 0 ? '+' : ''}${displayPercent(data?.score)})`
    }, [data])

    const renderUserProfitDesc = useMemo(() => {
        return data?.message
    }, [data])

    const renderUserProfitEmoji = useMemo(() => {
        return data?.emoji
    }, [data])

    const getTextLengthFontSize = useMemo(() => {
        if (renderUserProfitText()?.length > 24) {
            return 'text-landing_display2 font-bold'
        } else {
            return 'text-landing_display1'
        }
    }, [renderUserProfitText])

    return (
        <WizPopupModal
            title={t('dailyReport.title')}
            dialogClassName={className}
            onCloseCallback={() => setIsShowDailyReport(false)}
            titleClassName={'text-h4'}
            popupClassName={'sm:!w-[100%]'}
            layoutClassName={'md:!mx-[20px]'}
            ref={modalRef}>
            {Number(
                displayBalance(data?.earning, {
                    showPreSign: false,
                    isShowMinusSymbol: false,
                }),
            ) === 0 ? (
                <div
                    ref={divRef}
                    className={'pt-[5px] px-[10px] pb-[20px] sm:pb-[15px] font-landing bg-white dark:bg-bg_dark_popup'}>
                    <div className={''}>
                        <Text className={'text-[50px] leading-[70px] sm:text-[40px] sm:leading-[50px] text-center'}>
                            💡
                        </Text>
                    </div>
                    <Text className={'text-body2 sm:text-body3 text-gray02 dark:text-dark_gray02 text-center'}>
                        {t('dailyReport.date', {date: formattedUTCOffset(data?.created_at)})}
                    </Text>
                    <div className={'text-center'}>
                        <Span
                            className={`${getTextLengthFontSize} text-gray01 dark:text-dark_gray01 text-[29px] leading-[45px] font-bold sm:text-h3`}>
                            {t('dailyReport.mainText.same')}
                        </Span>

                        <div className={'inline-block relative ml-[5px]'}>
                            <div
                                className={
                                    'absolute w-full h-[15px] sm:h-[10px] bottom-[6px] sm:bottom-[3px] left-0 rounded-[40px] bg-gray07 dark:bg-dark_gray07'
                                }
                            />
                            <Span
                                className={
                                    '!font-poppins relative text-[29px] text-gray01 dark:text-dark_gray01 leading-[45px] font-bold sm:text-h3'
                                }>
                                ({displayBalance(0, {showPreSign: true})}, {displayPercent(0)})
                            </Span>
                        </div>
                    </div>
                    <div className={'mt-[10px] sm:mt-[5px]'}>
                        <Text className={'text-body1 sm:text-body3 text-gray02 dark:text-dark_gray02 text-center'}>
                            {t('dailyReport.mainText.same.desc')}
                        </Text>
                    </div>
                    <div
                        className={
                            'mt-[15px] bg-gray09 dark:bg-bg_dark_white02 rounded-[5px] py-[15px] px-[20px] sm:py-[10px] space-y-[10px]'
                        }>
                        <div className={'flex items-center justify-between'}>
                            <Text className={'text-body2 text-gray02 dark:text-dark_gray02 flex-1'}>
                                {t('dailyReport.totalValue')}
                            </Text>
                            <Text className={'text-body2 text-gray02 dark:text-dark_gray02 '}>
                                {displayBalance(data?.balance, {showPreSign: true})}
                            </Text>
                        </div>
                        <div className={'flex items-center justify-between'}>
                            <Text className={'text-body2 text-gray02 dark:text-dark_gray02 flex-1'}>
                                {t('dailyReport.earnings')}
                            </Text>
                            <Text
                                className={`text-body2 text-gray02 dark:text-dark_gray02 sm:text-body2 font-bold sm:font-bold `}>
                                {displayBalance(0, {showPreSign: true})}({displayPercent(0)})
                            </Text>
                        </div>
                        <div className={'flex items-center justify-between'}>
                            <Text className={'text-body2 text-gray02 dark:text-dark_gray02 flex-1'}>
                                {t('dailyReport.deposit')}
                            </Text>
                            <Text className={`text-body2 text-gray02 dark:text-dark_gray02`}>
                                {displayBalance(data?.deposits, {showPreSign: true})}
                            </Text>
                        </div>
                        <div className={'flex items-center justify-between'}>
                            <Text className={'text-body2 text-gray02 dark:text-dark_gray02 flex-1'}>
                                {t('dailyReport.withdraw')}
                            </Text>
                            <Text className={`text-body2 text-gray02 dark:text-dark_gray02`}>
                                {displayBalance(data?.withdraws, {showPreSign: true})}
                            </Text>
                        </div>
                    </div>
                    <div className={'mt-[15px]'}>
                        <WizImage src={img_logo_dark} containerClassName={'w-[150px] mx-auto hidden dark:block'} />
                        <WizImage src={img_logo} containerClassName={'w-[150px] mx-auto dark:hidden'} />
                    </div>
                </div>
            ) : (
                <div
                    ref={divRef}
                    className={'pt-[5px] px-[10px] pb-[20px]  font-landing bg-white dark:bg-bg_dark_popup'}>
                    <div className={''}>
                        <Text
                            className={
                                'text-[50px] leading-[70px] sm:text-[40px] sm:leading-[50px] text-center'
                            }>{`${renderUserProfitEmoji}`}</Text>
                    </div>
                    <Text className={'text-body2 sm:text-body3 text-gray02 dark:text-dark_gray02 text-center'}>
                        {t('dailyReport.date', {date: formattedUTCOffset(data?.created_at)})}
                    </Text>
                    <div className={'text-center'}>
                        {router?.locale === 'en' ? (
                            <>
                                <div className={'inline-block relative mr-[3px]'}>
                                    <div
                                        className={`absolute w-full h-[15px] sm:h-[10px] bottom-[6px] sm:bottom-[3px] left-0 rounded-[40px] ${getNumberHighLightColor}`}
                                    />
                                    <Span
                                        className={`${getTextLengthFontSize} !font-poppins sm:text-h3 relative ${getNumberColorClass(
                                            data?.earning,
                                        )}`}>
                                        {renderUserProfitText()}
                                    </Span>
                                </div>
                                <Span
                                    className={`${getTextLengthFontSize} !font-poppins text-gray01 dark:text-dark_gray01 sm:text-h3`}>
                                    {data?.earning >= 0
                                        ? t('dailyReport.mainText.en.increase')
                                        : t('dailyReport.mainText.en.decrease')}
                                </Span>
                            </>
                        ) : (
                            <>
                                <Span
                                    className={`${getTextLengthFontSize} !font-poppins text-gray01 dark:text-dark_gray01 sm:text-h3 block`}>
                                    {t('dailyReport.mainText.ko.text1')}
                                </Span>
                                <div className={'inline-block relative mr-[3px] !font-poppins'}>
                                    <div
                                        className={`absolute w-full h-[15px] sm:h-[10px] bottom-[6px] sm:bottom-[3px] left-0 rounded-[40px] ${getNumberHighLightColor}`}
                                    />
                                    <Span
                                        className={`${getTextLengthFontSize} sm:text-h3 relative ${getNumberColorClass(
                                            data?.earning,
                                        )}`}>
                                        {renderUserProfitText()}
                                    </Span>
                                </div>
                                <Span
                                    className={`${
                                        renderUserProfitText()?.length > 24
                                            ? 'text-landing_display2 font-bold'
                                            : 'text-landing_display1'
                                    } !font-poppins sm:text-h3 text-gray01 dark:text-dark_gray01`}>
                                    {data?.earning >= 0
                                        ? t('dailyReport.mainText.ko.text2.plus')
                                        : t('dailyReport.mainText.ko.text2.minus')}
                                </Span>
                            </>
                        )}
                    </div>
                    <div className={'mt-[10px] sm:mt-[5px]'}>
                        <Text className={'text-body1 sm:text-body3 text-gray02 dark:text-dark_gray02 text-center'}>
                            {renderUserProfitDesc}
                        </Text>
                    </div>
                    <div
                        className={
                            'mt-[15px] bg-gray09 dark:bg-bg_dark_white02 rounded-[5px] py-[15px] px-[20px] sm:py-[10px] space-y-[10px]'
                        }>
                        <div className={'flex items-center justify-between'}>
                            <Text className={'text-body2 text-gray02 dark:text-dark_gray02 flex-1'}>
                                {t('dailyReport.totalValue')}
                            </Text>
                            <Text className={'text-body2 text-gray02 dark:text-dark_gray02 '}>
                                {displayBalance(data?.balance, {showPreSign: true})}
                            </Text>
                        </div>
                        <div className={'flex items-center justify-between'}>
                            <Text className={'text-body2 text-gray02 dark:text-dark_gray02 flex-1'}>
                                {t('dailyReport.earnings')}
                            </Text>
                            <Text className={`text-body2 font-bold sm:font-bold ${getNumberColorClass(data?.earning)}`}>
                                {displayBalance(data?.earning, {showPreSign: true})}({displayPercent(data?.score)})
                            </Text>
                        </div>
                        <div className={'flex items-center justify-between'}>
                            <Text className={'text-body2 text-gray02 dark:text-dark_gray02 flex-1'}>
                                {t('dailyReport.deposit')}
                            </Text>
                            <Text className={`text-body2 text-gray02 dark:text-dark_gray02`}>
                                {displayBalance(data?.deposits, {showPreSign: true})}
                            </Text>
                        </div>
                        <div className={'flex items-center justify-between'}>
                            <Text className={'text-body2 text-gray02 dark:text-dark_gray02 flex-1'}>
                                {t('dailyReport.withdraw')}
                            </Text>
                            <Text className={`text-body2 text-gray02 dark:text-dark_gray02`}>
                                {displayBalance(data?.withdraws, {showPreSign: true})}
                            </Text>
                        </div>
                    </div>
                    <div className={'mt-[15px]'}>
                        <WizImage src={img_logo_dark} containerClassName={'w-[150px] mx-auto hidden dark:block'} />
                        <WizImage src={img_logo} containerClassName={'w-[150px] mx-auto dark:hidden'} />
                    </div>
                </div>
            )}
            <div>
                <div className={'flex items-center gap-[10px] sm:flex-col'}>
                    <WizButton
                        buttonType={'border'}
                        text={t('dailyReport.timeline.button.text')}
                        className={'w-full flex-1'}
                        onClick={() => {
                            createLog('event', 'daily_report_share_timeline')
                            router?.push(isSm ? URL_TIMELINE_POST_WITH_DAILY_REPORT : URL_TIMELINE_WITH_DAILY_REPORT)
                            modalRef?.current?.close()
                        }}
                    />
                    <WizButton
                        buttonType={'primary'}
                        text={t('dailyReport.button.text')}
                        className={'w-full flex-1'}
                        onClick={() => {
                            createLog('event', 'daily_report_check_my_portfolio')
                            goUserPortfolio('mine')
                            modalRef?.current?.close()
                        }}
                    />
                </div>
                <div
                    onClick={() => {
                        createLog('event', 'daily_report_save_my_portfolio')
                        onClickSaveDailyReport()
                        showSnackbar(t('dailyReport.snackbar.text'))
                    }}
                    className={'pt-[10px] cursor-pointer'}>
                    <Text className={'text-body2 text-gray02 dark:text-dark_gray02 text-center underline'}>
                        {t('dailyReport.save.text')}
                    </Text>
                </div>
            </div>
        </WizPopupModal>
    )
}

export default React.forwardRef(DailyReportModal)
