import React, {useContext} from 'react'
import WizImage from '@component/images/WizImage'
import imageSrcSayHello from '@image/login/img_login_modal_side_1.webp'
import imageSrcSafetyFirst from '@image/login/img_login_modal_side_2.webp'
import imageSrcWelcomeJoin from '@image/img_login_modal_side_3.png'
import {useTranslation} from '@lib/intl/useTranslations'
import Text from '@component/Text/Text'
import {equalString, isNotEmpty} from '@util/strings'
import {LoginModalContext} from '../../../../../../../pages/login/index.page'
import {SetLoginStateContext} from '@feature/common/components/LoginModal/LoginModalRouter'

interface IProps {
    type?: LoginSideImageType
}

export type LoginSideImageType = 'say_hello' | 'safety_first' | 'welcome_join'

const LoginModalSideImage: React.FC<IProps> = ({type = 'say_hello'}) => {
    const t = useTranslation()
    const {appliedReferral} = useContext(LoginModalContext)
    const {refCode} = useContext(SetLoginStateContext)

    const getStyleContainer = () => {
        const paddingStyle = 'px-[30px] pt-[60px]'
        return `${paddingStyle} w-full h-full min-h-[550px] flex flex-col items-center`
    }

    if (equalString(type, 'welcome_join')) {
        return (
            <div className={`${getStyleContainer()} bg-[#6A4FA5]`}>
                <Text className={'text-h3 text-white text-center'}>
                    {t('loginModal.sideImage.type.welcomeJoin.title')}
                </Text>
                <Text className={'text-body2 text-white text-center'}>
                    {t('loginModal.sideImage.type.welcomeJoin.desc')}
                </Text>
                <WizImage containerClassName={'mt-[10px]'} isFetchPriority={true} src={imageSrcWelcomeJoin} />
            </div>
        )
    } else if (equalString(type, 'safety_first')) {
        return (
            <div className={`${getStyleContainer()} bg-[#3761CC]`}>
                <Text className={'text-h3 text-white text-center'}>
                    {t('loginModal.sideImage.type.safetyFirst.title')}
                </Text>
                <Text className={'text-body2 text-white text-center'}>
                    {t('loginModal.sideImage.type.safetyFirst.desc')}
                </Text>
                <WizImage containerClassName={'mt-[10px]'} isFetchPriority={true} src={imageSrcSafetyFirst} />
            </div>
        )
    } else {
        return (
            <div
                className={`${getStyleContainer()} ${
                    isNotEmpty(appliedReferral || refCode)
                        ? `bg-[url('/images/login/img_referral_login.webp')] bg-no-repeat bg-center bg-cover mx-auto`
                        : 'bg-primary'
                }`}>
                <Text className={'text-h3 text-white text-center'}>
                    {t('loginModal.sideImage.type.sayHello.title')}
                </Text>
                <Text className={'text-body2 text-white text-center'}>
                    {t('loginModal.sideImage.type.sayHello.desc')}
                </Text>
                {!isNotEmpty(appliedReferral || refCode) && (
                    <WizImage isFetchPriority={true} containerClassName={'mt-[10px]'} src={imageSrcSayHello} />
                )}
            </div>
        )
    }
}

export default LoginModalSideImage
